import React, { PropsWithChildren } from 'react';
import styles from './Col.module.scss';
import { ColProps } from './Col.interface';
import cn from 'classnames';

const Col = ({
  children,
  className,
  oneFourth,
  oneThird,
  oneHalf,
  twoThird,
  threeFourth,
  breakpoint = 'lg',
  ...props
}: PropsWithChildren<ColProps>): JSX.Element => {
  return (
    <div
      {...props}
      className={cn(styles.col, className, {
        [styles.colOneFourth]: !!oneFourth && breakpoint === 'lg',
        [styles.colOneThird]: !!oneThird && breakpoint === 'lg',
        [styles.colOneHalf]: !!oneHalf && breakpoint === 'lg',
        [styles.colTwoThird]: !!twoThird && breakpoint === 'lg',
        [styles.colThreeFourth]: !!threeFourth && breakpoint === 'lg',
        [styles.colOneFourthMd]: !!oneFourth && breakpoint === 'md',
        [styles.colOneThirdMd]: !!oneThird && breakpoint === 'md',
        [styles.colOneHalfMd]: !!oneHalf && breakpoint === 'md',
        [styles.colTwoThirdMd]: !!twoThird && breakpoint === 'md',
        [styles.colThreeFourthMd]: !!threeFourth && breakpoint === 'md',
        [styles.colOneFourthSm]: !!oneFourth && breakpoint === 'sm',
        [styles.colOneThirdSm]: !!oneThird && breakpoint === 'sm',
        [styles.colOneHalfSm]: !!oneHalf && breakpoint === 'sm',
        [styles.colTwoThirdSm]: !!twoThird && breakpoint === 'sm',
        [styles.colThreeFourthSm]: !!threeFourth && breakpoint === 'sm',
      })}
    >
      {children}
    </div>
  );
};

export default Col;
