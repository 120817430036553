import React, { useState, useEffect } from 'react';
import MuiCheckbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FieldWrapper from './FieldWrapper';

type Props = {
  defaultChecked?: string[];
  helperText?: React.ReactNode | string;
  label?: string;
  disabled?: boolean;
  disabledPartial?: string[];
  name: string;
  options: {
    id: string;
    name: React.ReactNode | string;
    description?: React.ReactNode | string;
  }[];
  onChange?: (value: string[]) => void;
  required?: boolean;
  noMargin?: boolean;
  labelClassName?: string;
  updateAnyway?: boolean;
};

function CheckboxGroup({
  defaultChecked = [],
  disabledPartial = [],
  helperText,
  disabled,
  label,
  name,
  options,
  onChange: _onChange,
  required,
  noMargin,
  labelClassName,
  updateAnyway = false,
}: Props): JSX.Element {
  const val = useWatch<string[]>({ name });
  const [checked, setChecked] = useState<string[]>(defaultChecked || []);
  const { control, errors, setValue } = useFormContext();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist && e.persist();
    let value = [];
    const targetId = e.target.value;
    if (checked.includes(targetId)) {
      value = checked.filter((id) => id !== targetId);
    } else {
      value = [...checked, targetId];
    }
    !!_onChange && _onChange(value);
    setValue(name, value, { shouldValidate: updateAnyway || !!errors[name] });
  };
  useEffect(() => {
    !!defaultChecked.length && setValue(name, defaultChecked);
  }, []);
  useEffect(() => {
    setChecked(val ?? []);
  }, [val]);
  return (
    <FieldWrapper helperText={helperText} label={label} name={name} required={required} noMargin={noMargin}>
      <FormGroup>
        {options.map((option) => {
          return (
            <React.Fragment key={option.id}>
              <FormControlLabel
                disabled={disabled || disabledPartial.includes(option.id)}
                className={labelClassName}
                control={
                  <Controller
                    name={name}
                    control={control}
                    render={() => (
                      <MuiCheckbox
                        disabled={disabled || disabledPartial.includes(option.id)}
                        checked={checked.includes(option.id)}
                        color='primary'
                        onChange={onChange}
                        value={option.id}
                      />
                    )}
                  />
                }
                key={option.id}
                label={option.name}
              />
              {option.description}
            </React.Fragment>
          );
        })}
      </FormGroup>
    </FieldWrapper>
  );
}

export default CheckboxGroup;
