import React from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FieldWrapper from './FieldWrapper';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  description?: React.ReactNode | string;
  label?: React.ReactNode | string;
  name: string;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  required?: boolean;
  noMargin?: boolean;
  labelProps?: Omit<FormControlLabelProps, 'control' | 'label'>;
} & CheckboxProps;

function Checkbox({
  defaultChecked = false,
  description,
  label,
  name,
  required,
  noMargin,
  sx,
  labelProps,
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();
  return (
    <FormGroup {...{ sx }}>
      <FieldWrapper helperText={description} name={name} required={required} noMargin={noMargin}>
        <FormControlLabel
          control={
            <Controller
              defaultValue={defaultChecked}
              label={label}
              name={name}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <MuiCheckbox
                    checked={value}
                    color='primary'
                    onChange={(e) => onChange(e.target.checked)}
                    {...props}
                  />
                );
              }}
            />
          }
          label={label}
          {...labelProps}
        />
      </FieldWrapper>
    </FormGroup>
  );
}

export default Checkbox;
