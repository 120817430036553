import React from 'react';
import FieldWrapper from './FieldWrapper';
import { Controller, useFormContext } from 'react-hook-form';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import { FormHelperText, FormHelperTextProps } from '@mui/material';

type Props = {
  defaultValue?: string;
  description?: React.ReactNode | string;
  hidden?: boolean;
  label?: React.ReactNode | string;
  name: string;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  required?: boolean;
  value?: React.ReactNode | string;
  helperTextProps?: FormHelperTextProps;
} & TextFieldProps;

function TextField({
  description,
  hidden,
  label,
  name,
  onChange,
  required,
  defaultValue,
  helperText,
  helperTextProps,
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();
  return (
    <FieldWrapper helperText={description} hidden={hidden} label={label} name={name} required={required}>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ onChange: onChangeNative, value }) => (
          <>
            <MuiTextField
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                }
                onChangeNative(e);
              }}
              name={name}
              variant='outlined'
              value={value || defaultValue || ''}
              {...props}
            />
            <FormHelperText {...helperTextProps}>{helperText}</FormHelperText>
          </>
        )}
      />
    </FieldWrapper>
  );
}

export default TextField;
