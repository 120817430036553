import React, { PropsWithChildren } from 'react';
import styles from './PageTitle.module.scss';
import { Typography } from '@mui/material';
import { PageTitleProps } from './PageTitle.interface';
import cn from 'classnames';
import TextBlock from '../TextBlock/TextBlock';

export const PageTitle = ({
  size = 'large',
  align = 'left',
  hide = 'none',
  children,
  subTitle,
  component = 'h1',
  ...props
}: PropsWithChildren<PageTitleProps>): JSX.Element => {
  return (
    <>
      {children && (
        <Typography
          {...props}
          className={cn(styles.PageTitle, {
            [styles.hideDesktop]: hide === 'desktop',
            [styles.hideMobile]: hide === 'mobile',
            [styles.PageTitleLarge]: size === 'large',
            [styles.PageTitleLargeMedium]: size === 'large-medium',
            [styles.PageTitleHasSubTitle]: !!subTitle,
            [styles.PageTitleMediumLarge]: size === 'medium-large',
            [styles.PageTitleSmallMedium]: size === 'small-medium',
            [styles.PageTitleCheckout]: size === 'checkout-forms',
            [styles.PageTitleMedium]: size === 'medium',
            [styles.PageTitleSmall]: size === 'small',
            [styles.PageTitleCenter]: align === 'center',
          })}
          component={component}
          variant='h1'
        >
          {children}
        </Typography>
      )}
      {subTitle && (
        <TextBlock align='center' size={size !== 'medium' ? '14lg-12sm' : undefined}>
          {subTitle}
        </TextBlock>
      )}
    </>
  );
};
