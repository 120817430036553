import React, { PropsWithChildren } from 'react';
import styles from './FieldLabel.module.scss';
import cn from 'classnames';
import { FieldLabelProps } from './FieldLabel.interface';

const FieldLabel: React.FC<PropsWithChildren<FieldLabelProps>> = ({ className, variant = 'default', ...restProps }) => {
  return (
    <label
      className={cn(className, {
        [styles.fieldLabel]: variant === 'default',
        [styles.accountDialogEditPlanTravelProtectionLabel]: variant === 'editHHM',
      })}
      {...restProps}
    />
  );
};

export default FieldLabel;
